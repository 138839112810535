import React from 'react'
import { createStyles, Image, ListItem, Space } from '@mantine/core'
import { BlackWhiteInfoBox } from '../BlackWhiteInfoBox/BlackWhiteInfoBox'
import { Button } from '../Button/Button'
import { Text } from '../Text/Text'
import { ItemPrice } from '../ItemPrice/ItemPrice'
import { OverlayTag } from '../OverlayTag/OverlayTag'
import { ImageCarousel } from '../ImageCarousel/ImageCarousel'

import iconTravellersAutobarn from '../../assets/images/icon-travellers-autobarn.png'
import photoCarousel1 from '../../assets/images/photo-carousel-1.jpg'
import photoCarousel2 from '../../assets/images/photo-carousel-2.jpg'
import photoCarousel3 from '../../assets/images/photo-carousel-3.jpg'
import photoCarousel4 from '../../assets/images/photo-carousel-4.jpg'
import photoCarousel5 from '../../assets/images/photo-carousel-5.jpg'
import photoCarousel6 from '../../assets/images/photo-carousel-6.jpg'
import iconBaby from '../../assets/images/icon-baby.svg'

import { List } from '../List/List'
import { Anchor } from '../Anchor/Anchor'

const useStyles = createStyles((theme, _params) => {
  return {
    component: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 700,
      margin: '0 auto 50px auto',
      backgroundColor: theme.white,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.07)',

      [theme.fn.largerThan('md')]: {
        flexDirection: 'row',
        maxWidth: 'unset',
      },
    },

    imageCarouselContent: {
      height: 200,
      position: 'relative',
      userSelect: 'none',

      [theme.fn.largerThan('md')]: {
        width: '40%',
        maxWidth: '40%',
        height: 'unset',
        flexGrow: 1,
        flexShrink: 0,
      },
    },

    mainContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },

    mainTopContentContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      flexWrap: 'wrap',
    },

    topInfoBasicContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '22px 16px 0 16px',
      width: '100%',

      // A bit less than 'sm'
      [theme.fn.largerThan(650)]: {
        padding: '22px 0 0 16px',
        order: 0,
        width: 'auto',
        flexGrow: 1,
      },

      [theme.fn.largerThan('md')]: {
        padding: '28px 0 0 34px',
      },
    },

    topInfoDetailsContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: '22px 16px 0 16px',
      width: '100%',

      // A bit less than 'sm'
      [theme.fn.largerThan(650)]: {
        padding: '22px 0 0 16px',
        order: 2,
        width: 'auto',
      },

      [theme.fn.largerThan('md')]: {
        padding: '28px 0 0 34px',
      },
    },

    priceContent: {
      display: 'flex',
      gap: 20,
      flexDirection: 'column',
      padding: '22px 16px 0 16px',
      width: '100%',

      // A bit less than 'sm'
      [theme.fn.largerThan(650)]: {
        alignItems: 'flex-end',
        padding: '22px 16px 0 0',
        order: 1,
        width: 'auto',
      },

      [theme.fn.largerThan('md')]: {
        padding: '28px 34px 28px 0',
        minWidth: '50%',
      },
    },

    bottomInfoContent: {
      display: 'flex',
      gap: 20,
      flexDirection: 'column',
      padding: '22px 16px 32px 16px',

      // A bit less than 'sm'
      [theme.fn.largerThan(650)]: {
        flexDirection: 'row',
        alignItems: 'flex-end',
      },

      [theme.fn.largerThan('md')]: {
        padding: '28px 32px 28px 34px',
      },
    },

    bottomInfoContentLeft: {
      // A bit less than 'sm'
      [theme.fn.largerThan(650)]: {
        width: '100%',
        maxWidth: 290,
      },
    },

    bottomInfoContentRight: {
      // A bit less than 'sm'
      [theme.fn.largerThan(650)]: {
        width: '100%',
        maxWidth: 210,
        marginLeft: 'auto',
      },
    },
  }
})

export type ItemBoxProps = {
  imageCarouselContent?: React.ReactNode
  topInfoBasicContent?: React.ReactNode
  topInfoDetailsContent?: React.ReactNode
  priceContent?: React.ReactNode
  bottomInfoContent?: React.ReactNode
}

export function ItemBox({
  imageCarouselContent = ItemBox.ImageCarouselContent({}),
  topInfoBasicContent = ItemBox.TopInfoBasicContent({}),
  topInfoDetailsContent = ItemBox.TopInfoDetailsContent({}),
  priceContent = ItemBox.PriceContent({}),
  bottomInfoContent = ItemBox.BottomInfoContent({}),
}: ItemBoxProps) {
  const { classes } = useStyles()

  return (
    <div className={classes.component}>
      <div className={classes.imageCarouselContent}>{imageCarouselContent}</div>
      <div className={classes.mainContentContainer}>
        <div className={classes.mainTopContentContainer}>
          <div className={classes.topInfoBasicContent}>
            {topInfoBasicContent}
          </div>
          <div className={classes.topInfoDetailsContent}>
            {topInfoDetailsContent}
          </div>
          <div className={classes.priceContent}>{priceContent}</div>
        </div>
        <div className={classes.bottomInfoContent}>{bottomInfoContent}</div>
      </div>
    </div>
  )
}

/*

██╗███╗   ███╗ █████╗  ██████╗ ███████╗     ██████╗ █████╗ ██████╗  ██████╗ ██╗   ██╗███████╗███████╗██╗     
██║████╗ ████║██╔══██╗██╔════╝ ██╔════╝    ██╔════╝██╔══██╗██╔══██╗██╔═══██╗██║   ██║██╔════╝██╔════╝██║     
██║██╔████╔██║███████║██║  ███╗█████╗      ██║     ███████║██████╔╝██║   ██║██║   ██║███████╗█████╗  ██║     
██║██║╚██╔╝██║██╔══██║██║   ██║██╔══╝      ██║     ██╔══██║██╔══██╗██║   ██║██║   ██║╚════██║██╔══╝  ██║     
██║██║ ╚═╝ ██║██║  ██║╚██████╔╝███████╗    ╚██████╗██║  ██║██║  ██║╚██████╔╝╚██████╔╝███████║███████╗███████╗
╚═╝╚═╝     ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝     ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚══════╝
                                                                                                             
*/

ItemBox.ImageCarouselContent = function ({
  overlay = ItemBox.ImageCarouselOverlay({}),
  carousel = ItemBox.ImageCarouselCarousel({}),
}: {
  overlay?: React.ReactNode
  carousel?: React.ReactNode
}) {
  return (
    <>
      {overlay}
      {carousel}
    </>
  )
}

ItemBox.ImageCarouselOverlay = function ({
  overlayIconSrc = iconTravellersAutobarn,
  overlayText = 'Travellers Autobarn',
  ...rest
}: {
  overlayIconSrc?: string
  overlayText?: string
}) {
  return (
    <OverlayTag>
      {overlayIconSrc && <Image src={overlayIconSrc} height={50} {...rest} style={{ objectFit: 'contain', ...(rest?.style ?? {}) }} />}
      {overlayText}
    </OverlayTag>
  )
}

ItemBox.ImageCarouselCarousel = function ({
  images = [
    photoCarousel1,
    photoCarousel2,
    photoCarousel3,
    photoCarousel4,
    photoCarousel5,
    photoCarousel6,
  ],
  ...rest
}: {
  images?: string[]
}) {
  return <ImageCarousel images={images} {...rest} />
}

/*

████████╗ ██████╗ ██████╗     ██╗███╗   ██╗███████╗ ██████╗ 
╚══██╔══╝██╔═══██╗██╔══██╗    ██║████╗  ██║██╔════╝██╔═══██╗
   ██║   ██║   ██║██████╔╝    ██║██╔██╗ ██║█████╗  ██║   ██║
   ██║   ██║   ██║██╔═══╝     ██║██║╚██╗██║██╔══╝  ██║   ██║
   ██║   ╚██████╔╝██║         ██║██║ ╚████║██║     ╚██████╔╝
   ╚═╝    ╚═════╝ ╚═╝         ╚═╝╚═╝  ╚═══╝╚═╝      ╚═════╝ 
                                                            
*/

export type ItemBoxTopInfoBasicContent = {
  heading?: string
  info?: string
}

ItemBox.TopInfoBasicContent = function ({
  heading = 'Travellers Autobarn - Kuga',
  info = '4 passengers / 2 beds / Auto',
}: ItemBoxTopInfoBasicContent) {
  const itemHeadingTextStyle: React.CSSProperties = {
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: 600,
  }

  return (
    <>
      <Text styleType="custom" style={itemHeadingTextStyle}>
        {heading}
      </Text>
      <Text styleType="light">{info}</Text>
    </>
  )
}

export type ItemBoxTopInfoDetailsContent = {
  infoList?: string[]
  specsTitle?: string
  specsList?: { icon: string; text: string }[]
}

ItemBox.TopInfoDetailsContent = function ({
  infoList = ['Fridge', 'No Toiler & Shower', 'Newer Model'],
  specsTitle = 'Camper specs',
  specsList = [{ icon: iconBaby, text: '2 Baby / Booster Seats' }],
}: ItemBoxTopInfoDetailsContent) {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    flexWrap: 'wrap',
  }

  return (
    <div style={containerStyle}>
      <div>
        <List styleType="light">
          {infoList.map((info, i) => (
            <ListItem key={i}>{info}</ListItem>
          ))}
        </List>
      </div>
      <div>
        <Anchor styleType="bold">{specsTitle}</Anchor>
        <Space h={15} />
        {specsList.map(({ icon, text }, i) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <Image key={i + 'i'} src={icon} width={31} height={31} />
            <Text key={i + 't'} styleType="light">
              {text}
            </Text>
          </div>
        ))}
      </div>
    </div>
  )
}

/*

██████╗ ██████╗ ██╗ ██████╗███████╗
██╔══██╗██╔══██╗██║██╔════╝██╔════╝
██████╔╝██████╔╝██║██║     █████╗  
██╔═══╝ ██╔══██╗██║██║     ██╔══╝  
██║     ██║  ██║██║╚██████╗███████╗
╚═╝     ╚═╝  ╚═╝╚═╝ ╚═════╝╚══════╝
                                   
*/

ItemBox.PriceContent = function ({
  discountPrice = '$80.00',
  normalPrice = '$100.00',
  totalDiscountPriceLabel = 'Total discounted rate:',
  totalDiscountPrice = '$1600.00',
  timeUnit = ' / day',
}: {
  discountPrice?: string
  normalPrice?: string
  totalDiscountPriceLabel?: string
  totalDiscountPrice?: string
  timeUnit?: string
}) {
  return ItemPrice({
    discountPrice,
    normalPrice,
    totalDiscountPriceLabel,
    totalDiscountPrice,
    timeUnit,
  })
}

/*

██████╗  ██████╗ ████████╗████████╗ ██████╗ ███╗   ███╗    ██╗███╗   ██╗███████╗ ██████╗ 
██╔══██╗██╔═══██╗╚══██╔══╝╚══██╔══╝██╔═══██╗████╗ ████║    ██║████╗  ██║██╔════╝██╔═══██╗
██████╔╝██║   ██║   ██║      ██║   ██║   ██║██╔████╔██║    ██║██╔██╗ ██║█████╗  ██║   ██║
██╔══██╗██║   ██║   ██║      ██║   ██║   ██║██║╚██╔╝██║    ██║██║╚██╗██║██╔══╝  ██║   ██║
██████╔╝╚██████╔╝   ██║      ██║   ╚██████╔╝██║ ╚═╝ ██║    ██║██║ ╚████║██║     ╚██████╔╝
╚═════╝  ╚═════╝    ╚═╝      ╚═╝    ╚═════╝ ╚═╝     ╚═╝    ╚═╝╚═╝  ╚═══╝╚═╝      ╚═════╝ 
                                                                                         
*/

ItemBox.BottomInfoContent = function ({
  leftContent = ItemBox.BottomInfoBlackWhiteInfoBox({}),
  rightContent = ItemBox.BottomInfoButton({}),
}: {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { classes } = useStyles()

  return (
    <>
      <div className={classes.bottomInfoContentLeft}>{leftContent}</div>
      <div className={classes.bottomInfoContentRight}>{rightContent}</div>
    </>
  )
}

ItemBox.BottomInfoBlackWhiteInfoBox = function ({
  topText = '20% off Direct Rate',
  bottomLabel = 'Offer ends',
  bottomText = '6d 2hr 43d',
}: {
  topText?: string
  bottomLabel?: string
  bottomText?: string
}) {
  return BlackWhiteInfoBox({ topText, bottomLabel, bottomText })
}

ItemBox.BottomInfoButton = function ({
  text = 'Request This One',
  onClickRequestButton = () => alert('Item Box Request button clicked'),
}: {
  text?: string
  onClickRequestButton?: () => void
}) {
  return (
    <Button styleType="request" fullWidth onClick={onClickRequestButton}>
      {text}
    </Button>
  )
}
